var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import getDynamicAdUrl from '@/platform/jw-player/client/js/advertise/getDynamicAdUrl';
import getStaticAdUrl from '@/platform/jw-player/client/js/advertise/getStaticAdUrl';
import getAdSchedule from '@/platform/jw-player/client/js/advertise/getAdSchedule';
import jwPrebid, { pbjsLoaded } from '@/platform/jw-player/client/js/advertise/services/headerBidding/prebid';
import videoServerUrl from '@/platform/jw-player/client/js/advertise/services/headerBidding/prebid/videoServerUrl';
/**
 * Integrate Google IMA advertising logic.
 * Ref:
 *   - https://support.google.com/admanager/answer/10655276?hl=en&ref_topic=10684636#
 *   - https://alleyinteractive.atlassian.net/browse/NYP-14597
 *
 * NOTE:
 *   - The Ad URL is assigned in the JW Player Admin Dashboard under `Advertising`.
 *   - The Ad URL is unique per property (NYPost, P6, Decider)
 *
 * EXAMPLE:
 * For NYPost:
 *   https://securepubads.g.doubleclick.net/gampad/ads?
 *     sz=400x300
 *     &iu=/5850/post.videoplayer/jw
 *     &ciu_szs=300x250,300x251,300x600,970x250
 *     &impl=s
 *     &cust_params=permutive=[permutiveSegments]
 *     &testadv=[testadv]
 *     &player_name=[playerName]
 *     &content=[mediaInfoTags]
 *     &mediaId=[mediaInfoId]
 *     &mediaTitle=[mediaInfoName]
 *     &mediaDesc=[mediaInfoDescription]
 *     &mediaURL=[mediaURL]
 *     &gdfp_req=1
 *     &env=vp
 *     &output=vast
 *     &unviewed_position_start=1
 *     &correlator=[timestamp]
 *     &length=[playerDuration]
 *     &url=[windowLocationHref]
 *     &description_url=[descURL]
 *     &vpos=preroll
 *
 * @param {object} player The JW Player object.
 * @param {object} config Arguments passed to this plugin.
 */
function jwAdvertise(player, config) {
    var _this = this;
    // Get the player configuration.
    var playerConfig = player.getConfig();
    // Get the ad url from the ad schedule assigned to the player in the JWP dashboard.
    var adScheduleUrl = getAdSchedule(playerConfig);
    // Execute logic for only for players with a defined Ad Schedule URL.
    if (adScheduleUrl !== '') {
        // Setup Prebid.
        jwPrebid();
        // Perform macro string replacements on persistent data in a playlist.
        // ie: player name, window url location, etc.
        var baseAdUrl_1 = getStaticAdUrl(adScheduleUrl, config, playerConfig);
        // Begin logic for media in playlist.
        var mediaPlaylist = player.getPlaylist();
        if (typeof mediaPlaylist[0] === 'object') {
            // Perform macro string replacements on dynamic data specific to the media.
            var mediaAdUrl = getDynamicAdUrl(baseAdUrl_1, mediaPlaylist[0]);
            // Immediately set the ad schedule. See NYP-16198.
            Object.assign(mediaPlaylist[0], {
                adschedule: [{
                        tag: mediaAdUrl,
                        offset: 'pre',
                    }],
            });
        }
        // Apply media specific parameters to the Ad URL for
        // media in a playlist (videos 1, 2, 3, 4, and so on).
        player.setPlaylistItemCallback(function (item) { return __awaiter(_this, void 0, void 0, function () {
            var adItem;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // Immediately set the ad schedule. See NYP-16198.
                        Object.assign(item, {
                            adschedule: [{
                                    // Perform macro string replacements on dynamic data specific to the media.
                                    tag: getDynamicAdUrl(baseAdUrl_1, item),
                                    offset: 'pre',
                                }],
                        });
                        return [4 /*yield*/, pbjsLoaded()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, videoServerUrl(item)];
                    case 2:
                        adItem = _a.sent();
                        return [2 /*return*/, typeof adItem !== 'undefined' ? adItem : item];
                }
            });
        }); });
    }
}
var registerPlugin = window.jwplayerPluginJsonp || window.jwplayer().registerPlugin;
// Register the plugin.
registerPlugin('jwadvertise.bundle.min', '8.0', jwAdvertise);
